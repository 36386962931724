<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
const showType = [
  { label: '全部显示', value: 1 },
  { label: '列表显示', value: 2 },
  { label: '详细页显示', value: 3 },
  { label: '不显示', value: 4 },
];
export default {
  data() {
    return {
      search: [
        { name: '模版', type: 'text', fieldName: 'keyword', value: '' },
      ],
      config: {
        url: '/sms/list',
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        { name: '模版id', prop: 'template_id' },
        // { name: '模版内容', prop: 'content', width: '200px' },
        { name: '接收对象', prop: 'receive_type_text' },
        {
          name: '类型',
          func: (ops) => (Number(ops.send_type) === 1 ? '兑换成功' : '发货成功'),
        },
        { name: '显示位置',
          func: (ops) => {
            const el = showType.find((r) => r.value === ops.show_type);
            return el ? el.label : '未找到字典项';
          },
        },
        {
          name: '是否显示',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
        { fieldName: 'template_id', type: 'text', name: '模版id', value: '', required: true },
        {
          fieldName: 'receive_type',
          type: 'select',
          name: '接收对象',
          required: true,
          value: '',
          options: [
            { label: '收件人', value: 1 },
            { label: '兑换人', value: 2 },
          ],
        },
        {
          fieldName: 'send_type',
          type: 'select',
          name: '类型',
          required: true,
          value: '',
          options: [
            { label: '兑换成功', value: 1 },
            { label: '发货成功', value: 2 },
          ],
        },
        {
          fieldName: 'show_type',
          type: 'select',
          name: '显示位置',
          required: true,
          value: '',
          options: showType,
        },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { fieldName: 'content', type: 'textarea', name: '模版内容', value: '', required: true, width: '100%' },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/sms/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/sms/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
